import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { Cover } from "components/fostive/cover/cover"
import { FooterBrand } from "components/fostive/footer-brand/footer-brand"
import { Container } from "components/anti/grid/grid"
import { Link } from "components/anti/utils/utils"

import { replaceUnicode } from "src/utils/mixins"

// import bgSample from "assets/img/bg/bg-home.jpg"

const ContactPage = () => {
  const rawData = useStaticQuery(graphql`
    query {
      wordPress {
        pageBy(uri: "contact") {
          blocks {
            name
            ... on WordPress_AcfCoverMainBlock {
              coverMain {
                title
                img {
                  sourceUrl
                }
              }
            }
            ... on WordPress_AcfHeadlineWysiwygBlock {
              headlineWWYSIWYG {
                description
                headline
              }
            }
            ... on WordPress_AcfCustomContactBlock {
              contactWPhoneEmail {
                headline
                listPhone {
                  name
                  phone
                }
                email
              }
            }
          }
        }
      }
    }
  `)

  const blocks = rawData.wordPress.pageBy.blocks
  // console.log(blocks)
  const acfCoverMain = blocks.find(item => item.name === "acf/cover-main")
  const acfHeadlineWysiwyg = blocks.find(
    item => item.name === "acf/headline-wysiwyg"
  )
  const acfCustomContact = blocks.find(
    item => item.name === "acf/custom-contact"
  )

  const phoneFormat = param => {
    return param
      .replace("-", "")
      .replace(" ", "")
      .replace("+", "")
      .replace("-", "")
  }

  console.log({ acfCoverMain })

  return (
    <>
      <Layout footer={false}>
        <SEO title="Contact" />

        <Cover
          variant="basic"
          theme="dark"
          img={
            acfCoverMain &&
            acfCoverMain.coverMain &&
            acfCoverMain.coverMain.img.sourceUrl
          }
          imgHeight="h-vh-100"
          imgOverlay="30"
          className="cover-full text-center"
          contentMaxWidth="mw-100"
          sideDescription="contact"
          // videoType="video"
          // videoSrc={vidSample}
        >
          <div className="custom-sidebar d-none d-sm-block">
            <div>
              <h2 className="mb-5 animated fadeInUp delayp1">
                {acfCoverMain &&
                  acfCoverMain.coverMain &&
                  acfCoverMain.coverMain.title}
              </h2>
              <div className="reach-us mb-4">
                <h5 className="animated fadeInUp delayp2">
                  {replaceUnicode(acfCustomContact.contactWPhoneEmail.headline)}
                </h5>
                <p className="text-gray-fostive-50 animated fadeInUp delayp3">
                  {acfCustomContact.contactWPhoneEmail.listPhone.map(
                    (list, i) => {
                      return (
                        <span className="d-block" key={i}>
                          {list.name}{" "}
                          <Link
                            to={`tel:+${phoneFormat(list.phone)}`}
                            className="text-gray-fostive-50"
                          >
                            {list.phone}
                          </Link>
                        </span>
                      )
                    }
                  )}
                  <span>
                    <Link
                      to={`mailto:${acfCustomContact.contactWPhoneEmail.email}`}
                      className="text-gray-fostive-50"
                    >
                      {acfCustomContact.contactWPhoneEmail.email}
                    </Link>
                  </span>
                </p>
              </div>
              <div className="location">
                <h5 className="animated fadeInUp delayp4">
                  {replaceUnicode(acfHeadlineWysiwyg.headlineWWYSIWYG.headline)}
                </h5>
                <p className="text-gray-fostive-50 animated fadeInUp delayp5">
                  {replaceUnicode(
                    acfHeadlineWysiwyg.headlineWWYSIWYG.description
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="contact-sm-wrapper d-block d-sm-none">
            <div>
              <h2 className="mb-5">
                {acfCoverMain &&
                  acfCoverMain.coverMain &&
                  acfCoverMain.coverMain.title}
              </h2>
              <div className="reach-us mb-4">
                <h5>
                  {replaceUnicode(acfCustomContact.contactWPhoneEmail.headline)}
                </h5>
                <p className="text-gray-fostive-50">
                  {acfCustomContact.contactWPhoneEmail.listPhone.map(
                    (list, i) => {
                      return (
                        <span className="d-block" key={i}>
                          {list.name}{" "}
                          <Link
                            to={`tel:+${phoneFormat(list.phone)}`}
                            className="text-gray-fostive-50"
                          >
                            {list.phone}
                          </Link>
                        </span>
                      )
                    }
                  )}
                  <span>
                    <Link
                      to={`mailto:${acfCustomContact.contactWPhoneEmail.email}`}
                      className="text-gray-fostive-50"
                    >
                      {acfCustomContact.contactWPhoneEmail.email}
                    </Link>
                  </span>
                </p>
              </div>
              <div className="location">
                <h5>
                  {replaceUnicode(acfHeadlineWysiwyg.headlineWWYSIWYG.headline)}
                </h5>
                <p className="text-gray-fostive-50" className="text-white-50">
                  {replaceUnicode(
                    acfHeadlineWysiwyg.headlineWWYSIWYG.description
                  )}
                </p>
              </div>
            </div>
          </div>
          <Container className="mw-xl">
            <FooterBrand mobile={true} absolute={true} />
          </Container>
        </Cover>
      </Layout>
    </>
  )
}

export default ContactPage
